/* Slider */
.slick-slider {
  position: relative;

  display: block;
  box-sizing: border-box;

  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;

  display: block;
  overflow: hidden;

  margin: 0;
  padding: 0;
}

.slick-list:focus {
  outline: none;
}

.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  top: 0;
  left: 0;

  display: block;
  margin-left: auto;
  margin-right: auto;
}

.slick-track:before,
.slick-track:after {
  display: table;

  content: "";
}

.slick-track:after {
  clear: both;
}

.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  display: none;
  float: left;
  height: 100%;
  min-height: 1px;
}

[dir="rtl"] .slick-slide {
  float: right;
}

.slick-slide img {
  display: block;
}

.slick-slide.slick-loading img {
  display: none;
}

.slick-slide.dragging img {
  pointer-events: none;
}

.slick-initialized .slick-slide {
  display: block;
}

.slick-loading .slick-slide {
  visibility: hidden;
}

.slick-vertical .slick-slide {
  display: block;

  height: auto;

  border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
  display: none;
}

/* .carosel_work {
    width: 100%;
    height: 100vh;
} */

/* carosel */

span.counter {
  font-size: 50px !important;
  color: #000;
  font-weight: 600;
  line-height: 33px;
}

.count span {
  font-size: 24px;
  color: #000;
}

.count {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.react-3d-carousel .slider-container .slider-right div,
.react-3d-carousel .slider-container .slider-left div {
  border: 2px solid #fff;
}

.react-3d-carousel .slider-container .slider-content .slider-single.preactive .slider-single-content,
.react-3d-carousel .slider-container .slider-content .slider-single.proactive .slider-single-content {
  opacity: 0.8 !important;
}

.slider-single-content p {
  position: absolute;
  bottom: 35px;
  font-size: 25px;
  font-weight: 600;
  width: 26%;
}

.contents_ourwork {
  position: relative;
  transform: translateY(-60px);
  font-size: 20px;
  font-weight: 500;
}

.carosel_work .react-3d-carousel img {
  border-radius: 50px;
}

.background_service img {
  height: 662px;
  object-fit: cover;
}

.background_service {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.service_child {
  position: absolute;
}
 
.men_our {
  width: 50%;
}

img.men_our {
  clip-path: polygon(0 0%,
      0% 0,
      69% 0,
      100% 27%,
      100% 92%,
      100% 100%,
      0% 100%,
      0% 90%,
      0% 10%);
}

.main_carts {
  padding-top: 80px;
}

figcaption h5 {
  margin-top: 3px;
  margin-bottom: 3px;
  font-size: 17px;
  text-transform: capitalize;
  font-weight: 500;
}

figcaption p {
  margin-bottom: 3px;
  font-size: 14px;
  text-transform: capitalize;
  font-weight: 400;
}

.main_carts {
  text-align: center;
}

.team_card {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

figcaption {
  background-color: rgb(20, 20, 20);
  width: 50%;
}

.team_card {
  display: flex;
}

.our_team {
  padding-bottom: 50px;
}

.contactus {
  padding-top: 100px;
}

.contactus input.form-control,
.contactus textarea.form-control {
  margin-bottom: 18px;
  background-color: rgb(38, 38, 38);
  color: #fff;
  border: none;
}

.contactus input.form-control::placeholder,
.contactus textarea.form-control::placeholder {
  font-size: 14px;
}

.contactus button {
  float: right;
  background-color: red;
  color: #fff;
  border: none;
  padding: 9px 32px;
  border-radius: 21px;
}

.arrow {
  border: solid #fff;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 4px;
  margin-left: 12px;
}

.right {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}

img.supplers {
  clip-path: polygon(0 0%,
      0% 0,
      100% 0,
      100% 35%,
      100% 88%,
      100% 100%,
      41% 101%,
      36% 90%,
      0% 10%);
}

img.supplers {
  position: relative;
  transform: translateY(-50px);
}

.what_wetext {
  position: relative;
  justify-content: center;
  align-items: center;
  display: flex;
}

.what_wetext p {
  position: absolute;
  content: "";
  color: #fff;
  font-size: 25px;
  text-transform: capitalize;
  z-index: 55;
  font-weight: 600;
  width: 84%;
}

.image {
  position: relative;
}

.image img {
  position: relative;
  z-index: 0;
  display: block;
}

.image .fad {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 152px;
  z-index: 1;
  background: rgba(0, 0, 0, 0) linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.8) 90%) repeat scroll 0 0;
}

.work_title p {
  margin-bottom: 3px;
  font-size: 40px;
  font-weight: 600;
  line-height: 70px;
}

.image .work_title p {
  margin-bottom: 3px;
  font-size: 70px;
  font-weight: 600;
  line-height: 70px;
}

.image .work_title {
  z-index: 55;
}

.work_title {
  color: #fff;
  position: absolute;
  padding-bottom: 30px;
}

.ourwork_title {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: end;
}

.background_intex {
  position: relative;
  display: flex;
  justify-content: center;
}

.work_title_intex {
  color: #fff;
  position: absolute;
  bottom: -70px;
  padding-bottom: 30px;
  width: 82%;
  transform: translateX(32px);
}

.work_title_intex p {
  font-weight: 600;
  font-size: 32px;
  margin-bottom: 3px;
}

.work_title_intex span {
  font-weight: 500;
  margin-bottom: 3px;
}

.banner_images {
  height: 100vh !important;
}

.slider_main_card .item a {
  color: #fff !important;
  text-decoration: none !important;
}

.blogs_events p {
  margin: 0px;
}

@keyframes vertical {
  0% {
    opacity: 0;
  }

  4% {
    opacity: 0;
    -webkit-transform: rotateX(-180deg);
    transform: rotateX(-180deg);
  }

  10% {
    opacity: 1;
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
  }

  28% {
    opacity: 1;
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
  }

  48% {
    opacity: 0;
    -webkit-transform: translateY(-90px);
    transform: translateY(-90px);
  }

  80% {
    opacity: 0;
  }

  100% {
    opacity: 0;
  }
}

.verticalflip {
  font-size: 24px;
  position: relative;
  overflow: hidden !important;
}

.verticalflip p {
  font-size: 28px;
}

.verticalflip span {
  animation: vertical 9.12s linear infinite 0s;
  -ms-animation: vertical 9.12s linear infinite 0s;
  -webkit-animation: vertical 9.12s linear infinite 0s;
  color: #8729ca;
  position: absolute;
  border-radius: 10px;
  opacity: 0;
  left: 230px;
  top: 15px;
  padding: 3px;
  font-size: 28px;
  font-weight: 600;
}

.verticalflip span:nth-child(2) {
  -ms-animation-delay: 2.3s;
  -webkit-animation-delay: 2.3s;
  animation-delay: 2.3s;
}

.verticalflip span:nth-child(3) {
  -ms-animation-delay: 4.6s;
  -webkit-animation-delay: 4.6s;
  animation-delay: 4.6s;
}

.verticalflip span:nth-child(4) {
  -ms-animation-delay: 6.9s;
  -webkit-animation-delay: 6.9s;
  animation-delay: 6.9s;
}

@media screen and (max-width: 600px) {
  .work_title {
    padding-bottom: 3px;
  }

  .work_title p {
    margin-bottom: 0px;
    font-size: 15px;
    font-weight: 500;
    line-height: 17px;
  }

  .work_title span {
    font-size: 12px;
  }
}